import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_METRICAS);

class Metricas_SLA {
  obtener_etapas(options) {
    return http.get(utils.url_options(`/sla/etapas`,options));
  }

  obtener_etapa(id) {
    return http.get(`/sla/etapas/${id}`);
  }

  crear_etapa(payload) {
    return http.post(`/sla/etapas`, payload);
  }

  editar_etapa(id, payload) {
    return http.put(`/sla/etapas/${id}`, payload);
  }

  eliminar_etapa(id) {
    return http.delete(`/sla/etapas/${id}`);
  }

  obtener_solicitudes(options) {
    return http.get(utils.url_options(`/sla`,options));
  }

  obtener_solicitud(solicitud) {
    return http.get(`/sla/${solicitud}`);
  }

  obtener_cancelacion(solicitud) {
    return http.get(`/sla/${solicitud}/cancelado`);
  }
}

export default new Metricas_SLA();